const isUndefined = require('lodash/isUndefined');

function addEmbedScript() {
  const body = !isUndefined(document) && document.body;
  const script = !isUndefined(document) && document.createElement('script');
  script.setAttribute('src', '/scripts/embed.js');
  script.setAttribute("type", "text/javascript");
  script.setAttribute("charset", "utf8");
  script.setAttribute("async", "");
  body.appendChild(script);
}

function addHeadScript(scriptText) {
  const head = !isUndefined(document) && document.head;
  const script = !isUndefined(document) && document.createElement('script');
  script.text = scriptText
  script.setAttribute("type", "text/javascript");
  script.setAttribute("charset", "utf8");
  script.setAttribute("async", "");
  head.appendChild(script)
}

module.exports = {
  addEmbedScript,
  addHeadScript
}