import React from 'react';

export const FacekookPixel = (props) => {
  return (
    <script type="text/javascript" dangerouslySetInnerHTML={{
      __html:`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${props.facebookId}, { cat: '{{_cat_}}', recurring:'{{_recurring_}}', sp: '{{_sp_}}', k: '{{_k_}}', 'm_us': '{{_m_us_}}', reason: '{{_reason_}}', '_filteredParams': '{{__filteredParams_}}');

        fbq('track', 'PageView');
    `}} async />
  )
}

export const SafekidsFacebookPixel = () => {
  return <FacekookPixel facebookId={'869300963409925'} />
}

export const DefaultFacebookPixel = () => {
  return <FacekookPixel facebookId={'1683239578555307'} />
}

export const facebookPixel = (facebookId) => {
  return (
    `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${facebookId}, { cat: '{{_cat_}}', recurring:'{{_recurring_}}', index: '{{_index_}}', type: '{{_type_}}', sp: '{{_sp_}}', k: '{{_k_}}', m_us: '{{_m_us_}}', m_c: '{{_m_c_}}', m_t: '{{_m_t_}}', reason: '{{_reason_}}', _filteredParams: '{{__filteredParams_}}'});

      fbq('track', 'PageView');
    `
  )
}

export const defaultFacebookPixel = facebookPixel('1683239578555307')

export const safekidsFacebookPixel = facebookPixel('869300963409925')
